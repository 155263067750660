import '@/themes/styles.css'
import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { DevTools } from 'jotai-devtools'
import 'moment/dist/locale/pt-br'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { RouterProvider } from 'react-router-dom'
import { queryClient } from './configurações/configuraçõesApi'
import { configRouter } from './rotas/Router'
import tema from './themes/tema'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { PostHogProvider } from 'posthog-js/react'
import React from 'react'
import posthog from 'posthog-js'

Sentry.init({
  dsn: 'https://73918c12064d0d10dc9861fa06e81f78@o4507470069235712.ingest.us.sentry.io/4507470071857152',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://preview.app.esforce.com.br', 'https://app.esforce.com.br'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
const { ToastContainer } = createStandaloneToast()

const devToolsJotai = import.meta.env.VITE_DEV_TOOLS_JOTAI
const devToolsReactQuery = import.meta.env.VITE_DEV_TOOLS_REACT_QUERY
const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID
const posthogHost = import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST
const posthogKey = import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY

posthog.init(posthogKey, {
  api_host: posthogHost,
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <GoogleOAuthProvider clientId={googleClientId}>
        <ChakraProvider theme={tema} resetScope="no">
          <QueryClientProvider client={queryClient}>
            {devToolsJotai === 'true' && <DevTools theme="dark" />}
            <HelmetProvider>
              <RouterProvider router={configRouter} />
              <ToastContainer />
            </HelmetProvider>
            {devToolsReactQuery === 'true' && (
              <ReactQueryDevtools initialIsOpen={false} toggleButtonProps={{ className: 'btn-react-query-dev-tools' }} />
            )}
          </QueryClientProvider>
        </ChakraProvider>
      </GoogleOAuthProvider>
    </PostHogProvider>
  </React.StrictMode>,
)
