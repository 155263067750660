import { AlunoModel } from '@/@types/Models'
import { api } from '@/api/api'
import Yup from '@/utilitários/Yup/Yup'
import { abstractSchema } from '@/utilitários/Yup/abstractSchema'
import { mensagemErroSchema } from '@/utilitários/Yup/mensagemErroSchema'
import { responseError, responseSuccess } from '@/utilitários/handler/responseHandler'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { rotasApiAutenticadoV5 } from '../../../configurações/rotasApi/rotasApiAutenticadoV5'

const { path } = rotasApiAutenticadoV5.completarVerificacao

export const useCadastroWithGoogle = () => {
  const {
    mutate: cadastroAlunoWithgoogle,
    isLoading: cadastroAlunoWithgoogleIsLoading,
    status: cadastroAlunoWithgoogleStatus,
  } = useMutation(endPoint, {
    onSuccess: () => {
      responseSuccess('Cadastro realizado')
    },
    onError: (erro: AxiosError) => responseError(erro),
  })

  const cadastroAlunoWithgoogleContext = useForm({
    resolver: yupResolver(cadastroAlunoWithgoogleContextSchema),
    reValidateMode: 'onSubmit',
  })

  return {
    cadastroAlunoWithgoogle,
    cadastroAlunoWithgoogleContext,
    cadastroAlunoWithgoogleIsLoading,
    cadastroAlunoWithgoogleStatus,
  }
}

async function endPoint(dataNew: CadastroAlunoWithgoogleContextSchema): Promise<AlunoModel> {
  const data = {
    matricula: dataNew.matricula,
    instituicaoId: dataNew.instituicaoId,
  }
  const headers = {
    'google-id-token': dataNew.tokenGoogleId,
  }
  const result = await api().post(path, data, { headers })
  return result.data.data
}

export const cadastroAlunoWithgoogleContextSchema = Yup.object({
  matricula: abstractSchema.matrícula.required(mensagemErroSchema.required),
  instituicaoId: Yup.string().required('Informe uma instituição'),
  tokenGoogleId: Yup.string().optional().nullable(),
})

export type CadastroAlunoWithgoogleContextSchema = Yup.InferType<typeof cadastroAlunoWithgoogleContextSchema>
