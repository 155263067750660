import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { useMostrarSenha } from '@/componentes/CompositionsPatterns/Formulário/useMostrarSenha'
import { ContainerLayoutSemLayout } from '@/componentes/ContainerLayoutSemLayout'
import { LinkUi } from '@/componentes/LinkUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXxlUi } from '@/componentes/Textos/TextoXxlUi'
import { coresUi } from '@/configurações/estilização/cores'
import { gapUi } from '@/configurações/estilização/medidas'
import { fontSizeUi } from '@/configurações/estilização/tipografia'
import { rotasApp } from '@/configurações/rotasApp'
import { useLogin } from '@/hooks/API/autenticação/useLogin'
import { isErrosFormulário } from '@/utilitários/isErrosFormulário'
import { Flex, Img, Text } from '@chakra-ui/react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { primeiroAcessoRotasApp } from '../primeiroAcesso/primeiroAcesso.routes'
import { useLoginWithGoogle } from '../../../hooks/API/autenticação/useLoginWithGoogle'
import { useEffect, useState } from 'react'
import { GoogleLogin } from '@react-oauth/google'
import { TextoLgUi } from '../../../componentes/Textos/TextoLgUi'
import { Oval } from 'react-loader-spinner'

export const LoginPágina = () => {
  const { loginWithGoogle, isLoadingLoginWithGoogle } = useLoginWithGoogle()
  const { loginContext, login } = useLogin()
  const { MostrarSenha, verSenha } = useMostrarSenha()
  const navigate = useNavigate()
  const [credential, setCredential] = useState<string>()

  useEffect(() => {
    if (credential) {
      loginWithGoogle({ tokenGoogleId: credential })
    }
  }, [credential])
  return (
    <ContainerLayoutSemLayout>
      {isLoadingLoginWithGoogle && (
        <Flex
          bgColor={'blackAlpha.600'}
          w="100%"
          h="100vh"
          position="absolute"
          top="0"
          left={0}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          zIndex={99}
        >
          <Oval
            visible={true}
            height="80"
            width="80"
            color={coresUi['primaria-ativo']}
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </Flex>
      )}
      <Img src="/logo.png" alt="Logo da empresa" width="250px" alignSelf="center" />
      <TextoXxlUi color={coresUi['primaria-ativo']} alignSelf="start" fontWeight="500">
        Bem-vindo(a)
      </TextoXxlUi>

      <FormProvider {...loginContext}>
        <FormulárioUi.Form callback={login}>
          <Flex width="100%" flexDirection="column" gap={gapUi['4xl']}>
            <Flex width="100%" flexDirection="column" gap={gapUi.xl}>
              <FormulárioUi.Container name="email">
                <FormulárioUi.Label título="Email" />
                <FormulárioUi.Input placeholder="Digite seu email" autoComplete="username" />
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
              <FormulárioUi.Container name="senha">
                <FormulárioUi.Label título="Senha" />
                <FormulárioUi.Input
                  placeholder="Digite sua senha"
                  autoComplete="current-password"
                  type={verSenha ? 'text' : 'password'}
                />
                <Flex width="100%" alignItems="center" justifyContent="space-between">
                  <MostrarSenha />
                  <LinkUi rota={rotasApp.recuperarSenhaMatrícula} fontSize={fontSizeUi.sm} color={coresUi['primaria-ativo']}>
                    Esqueci minha senha
                  </LinkUi>
                </Flex>
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
            </Flex>
            <BotãoFormulárioUi isLoading={false} type="submit" isDisabled={isErrosFormulário(loginContext.formState.errors)}>
              Acessar minha conta
            </BotãoFormulárioUi>
            <TextoSmUi alignSelf="center">
              Não possui uma conta?
              <Text
                color={coresUi['primaria-ativo']}
                as="span"
                paddingY="10px"
                onClick={() => navigate(primeiroAcessoRotasApp.buscarMatricula)}
              >
                {' '}
                Realizar cadastro
              </Text>
            </TextoSmUi>

            <TextoLgUi alignSelf="center"> ou</TextoLgUi>
          </Flex>
        </FormulárioUi.Form>
      </FormProvider>

      <Flex width="100%" flexDirection="column" gap={gapUi['4xl']}>
        <Flex width="100%" flexDirection="column" justifyContent="center" alignItems="center" gap={gapUi.xl}>
          <GoogleLogin
            type="standard"
            text="signin_with"
            size="large"
            use_fedcm_for_prompt={true}
            onSuccess={(credentialResponse) => {
              if (credentialResponse) {
                setCredential(credentialResponse.credential)
              }
            }}
            useOneTap
          />
        </Flex>
      </Flex>
    </ContainerLayoutSemLayout>
  )
}
