import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { TextoLgUi } from '@/componentes/Textos/TextoLgUi'
import { TextoMdUi } from '@/componentes/Textos/TextoMdUi'
import { TextoSmUi } from '@/componentes/Textos/TextoSmUi'
import { TextoXxlUi } from '@/componentes/Textos/TextoXxlUi'
import { coresUi, corTextoUi } from '@/configurações/estilização/cores'
import { gapUi, paddingUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { useResponsávelAutorizarAluno } from '@/hooks/API/responsável/useResponsávelAutorizarAluno'
import { useModalUi } from '@/hooks/useModalUi'
import { LocalStorage } from '@/utilitários/localStorage/LocalStorage'
import { Flex, ListItem, UnorderedList, Img } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const AutorizarAlunoConfirmar = () => {
  const { autorizarAlunoMutate, autorizarAlunoIsLoading, autorizarAlunoStatus, autorizarAlunoData } =
    useResponsávelAutorizarAluno()

  const { UiModal, onOpen: openModal } = useModalUi()
  const navigate = useNavigate()
  const storage = new LocalStorage()

  const aluno = {
    id: storage.get('idAluno') as string,
    nome: storage.get('nomeAluno') as string,
    cpf: storage.get('cpfAluno') as string,
    email: storage.get('emailAluno') as string,
    telefone: storage.get('telefoneAluno') as string,
    instituicao: storage.get('instituicao') as string,
    turma: storage.get('turma') as string,
  }

  const responsável = {
    nome: storage.get('nomeResponsavel') as string,
    cpf: storage.get('cpfResponsavel') as string,
    telefone: storage.get('telefoneResponsavel') as string,
    email: storage.get('emailResponsavel') as string,
  }

  const autorizacaoData = {
    alunoId: aluno.id,
    responsavelCPF: responsável.cpf,
  }

  useEffect(() => {
    if (autorizarAlunoStatus === 'success') {
      openModal()
      storage.remove('idAluno')
      storage.remove('nomeAluno')
      storage.remove('cpfAluno')
      storage.remove('telefoneAluno')
      storage.remove('emailAluno')
      storage.remove('turma')
      storage.remove('instituicao')
      storage.remove('nomeResponsavel')
      storage.remove('cpfResponsavel')
      storage.remove('telefoneResponsavel')
      storage.remove('emailResponsavel')
    }
  }, [autorizarAlunoStatus])

  return (
    <>
      <FlexColumnUi maxWidth={500} gap={gapUi['6xl']} padding={paddingUi.md}>
        <Img src="/logo.png" alt="Logo da empresa" width="250px" alignSelf="center" />
        <FlexColumnUi gap={gapUi.md}>
          <TextoXxlUi color={coresUi['primaria-ativo']} fontWeight="500">
            Autorizar acesso
          </TextoXxlUi>
          <TextoSmUi color={corTextoUi.primaria} lineHeight="125%">
            Eu, {responsável.nome?.toUpperCase()}, portador do documento CPF nº {responsável.cpf}, responsável pelo(a) menor&nbsp;
            {aluno.nome?.toUpperCase()}
            {aluno.cpf !== 'null' && `, portador do documento CPF nº${aluno.cpf}`}, autorizo o acesso do(a) mesmo(a) à plataforma
            Esforce, e confirmo a veracidade dos dados abaixo:
          </TextoSmUi>
        </FlexColumnUi>

        <FlexColumnUi gap={gapUi['2xl']}>
          <FlexColumnUi paddingStart={paddingUi.lg} gap={gapUi.lg}>
            <TextoLgUi color={coresUi['secundaria-ativo']} fontWeight="500">
              Aluno(a)
            </TextoLgUi>
            <UnorderedList paddingStart={paddingUi.xl}>
              <ListItem>{aluno.nome?.toUpperCase()}</ListItem>
              <ListItem>CPF: {aluno.cpf !== 'null' ? aluno.cpf : 'Ausente'}</ListItem>
              <ListItem>telefone: {aluno.telefone}</ListItem>
              <ListItem>email: {aluno.email}</ListItem>
              <ListItem>instituição: {aluno.instituicao}</ListItem>
              <ListItem>turma: {aluno.turma}</ListItem>
            </UnorderedList>
          </FlexColumnUi>

          <FlexColumnUi paddingStart={paddingUi.lg} gap={gapUi.lg}>
            <TextoLgUi color={coresUi['secundaria-ativo']} fontWeight="500">
              Responsável
            </TextoLgUi>
            <UnorderedList paddingStart={paddingUi.xl}>
              <ListItem>{responsável.nome?.toUpperCase()}</ListItem>
              <ListItem>CPF: {responsável.cpf}</ListItem>
              <ListItem>telefone: {responsável.telefone}</ListItem>
              <ListItem>email: {responsável.email}</ListItem>
            </UnorderedList>
          </FlexColumnUi>
        </FlexColumnUi>

        <Flex width="100%" flexDirection="column" alignItems="center" gap={gapUi.md}>
          <BotãoFormulárioUi
            type="button"
            isLoading={false}
            isDisabled={autorizarAlunoIsLoading}
            callback={() => autorizarAlunoMutate({ ...autorizacaoData, autorizacaoStatus: true })}
          >
            {autorizarAlunoIsLoading ? 'CONFIRMANDO...' : 'CONFIRMAR'}
          </BotãoFormulárioUi>
          <TextoMdUi
            color={coresUi['primaria-ativo']}
            padding={paddingUi['2xl']}
            onClick={() => autorizarAlunoMutate({ ...autorizacaoData, autorizacaoStatus: false })}
          >
            Recusar acesso
          </TextoMdUi>
        </Flex>
      </FlexColumnUi>

      <UiModal
        título={autorizarAlunoData ? 'Acesso liberado' : 'Acesso bloqueado'}
        descrição={`Acesso à plataforma Esforce ${autorizarAlunoData ? 'liberado' : 'bloqueado'} `}
        callbackBotão={() => navigate(rotasApp.login)}
        textoBotão="Entendido"
      />
    </>
  )
}
