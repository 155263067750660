import { AvatarUsuárioAtivo } from '@/componentes/AvatarUsuárioAtivo'
import { BotãoFormulárioUi } from '@/componentes/Botões/BotãoFormulárioUi'
import { FormulárioUi } from '@/componentes/CompositionsPatterns/Formulário/FormulárioUi'
import { ContainerAppUi } from '@/componentes/ContainerAppUi'
import { FlexColumnUi } from '@/componentes/FlexColumnUi'
import { configuraçõesApi } from '@/configurações/configuraçõesApi'
import { coresUi, corFundoUi, corIconesUi, corPrimariaAtivoUi } from '@/configurações/estilização/cores'
import { gapUi, marginUi } from '@/configurações/estilização/medidas'
import { rotasApp } from '@/configurações/rotasApp'
import { useAlunoBuscarPorId } from '@/hooks/API/aluno/useAlunoBuscarPorId'
import { useAlunoEditar } from '@/hooks/API/aluno/useAlunoEditar'
import { useUsuárioAtivoAtom } from '@/hooks/jotai/useUsuárioAtivoAtom'
import { useContextoOutletLayoutComNavegação } from '@/hooks/useLayoutComNavegação'
import { useModalUi } from '@/hooks/useModalUi'
import { isErrosFormulário } from '@/utilitários/isErrosFormulário'
import { AbsoluteCenter, Box, Divider, Flex, Icon } from '@chakra-ui/react'
import { ImageSquare, Trash } from '@phosphor-icons/react'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { TextoLgUi } from '../../../componentes/Textos/TextoLgUi'
import { fontSizeUi } from '../../../configurações/estilização/tipografia'
import { FieldNames } from '../../../utilitários/fieldnames'

export const EditarPerfilPágina = () => {
  useContextoOutletLayoutComNavegação({
    título: 'Editar perfil',
    isBotãoEsquerdo: true,
  })

  const { UiModal, onOpen } = useModalUi()
  const { usuárioAtivo } = useUsuárioAtivoAtom()
  const { alunoEditarContext, alunoEditar, alunoEditarIsLoading, alunoEditarStatus } = useAlunoEditar()
  const { setValue, watch, formState } = alunoEditarContext
  const { errors } = formState
  const navigate = useNavigate()

  const { aluno } = useAlunoBuscarPorId({
    alunoId: usuárioAtivo?.Perfil?.alunoId,
  })
  const [isEdição, setIsEdição] = useState<boolean>(false)

  // useEffect(() => {

  //   const timer = setTimeout(() => {
  //     const inputElement = document.querySelector('.inputRef');
  //     if (inputElement) {
  //       inputElement.focus()
  //       inputElement.scrollIntoView({ behavior: 'smooth' })
  //     }
  //   }, 250)

  //   return () => clearTimeout(timer)
  // }, [])

  useEffect(() => {
    const ediçãoEmail = watch('email') === aluno?.email
    const ediçãoCelular = watch('celular') === aluno?.celular
    const ediçãoNomeResponsavel = watch('nomeResponsavel') === aluno?.Responsavel?.nome
    const ediçãoEmailREsponsavel = watch('emailResponsavel') === aluno?.Responsavel?.email
    const ediçãoCPFResponsavel = watch('cpfResponsavel') === aluno?.Responsavel?.cpf
    const ediçãoTelefoneResponsavel = watch('telefoneResponsavel') === aluno?.Responsavel?.telefone

    const updateFoto = watch('updateFoto')
    if (
      ediçãoEmail &&
      ediçãoCelular &&
      !updateFoto &&
      ediçãoNomeResponsavel &&
      ediçãoEmailREsponsavel &&
      ediçãoCPFResponsavel &&
      ediçãoTelefoneResponsavel
    )
      setIsEdição(false)
    else setIsEdição(true)
  }, [watch()])

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader()
    const file = event.target.files?.[0]
    setValue('avatar', file)
    setValue('deletarFoto', false)
    setValue('updateFoto', true)
    reader.onloadend = () => {
      setValue('avatarUrl', reader.result as string)
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  // const lidaComRemoverFoto = () => {
  //   setValue('avatarUrl', '')
  //   setValue('avatar', '')
  //   setValue('deletarFoto', true)
  //   setValue('updateFoto', true)
  // }

  const avatarWatch = watch('avatarUrl')
  const AvatarRender = useCallback(
    () => <AvatarUsuárioAtivo src={avatarWatch?.includes('base64') ? avatarWatch : configuraçõesApi.baseArquivo('')} />,
    [avatarWatch],
  )

  useEffect(() => {
    if (!aluno) return
    setValue('id', aluno.id)
    setValue('matricula', aluno.matricula)
    setValue('email', aluno.email ? aluno.email : '')
    setValue('avatarUrl', aluno.avatarUrl)
    setValue('celular', aluno.celular ? aluno.celular : '')
    setValue('idade', aluno.idade)

    if (!aluno.Responsavel) return
    setValue('nomeResponsavel', aluno.Responsavel.nome || '')
    setValue('emailResponsavel', aluno.Responsavel.email || '')
    setValue('cpfResponsavel', aluno.Responsavel.cpf || '')
    setValue('telefoneResponsavel', aluno.Responsavel.telefone || '')
  }, [JSON.stringify(aluno)])

  const verifyField = (value: FieldNames) => {
    if (!value) return
    if (watch(value) === '') return true
  }

  useEffect(() => {
    if (alunoEditarStatus === 'success') onOpen()
  }, [alunoEditarStatus])

  return (
    <ContainerAppUi ResetCSS>
      <FlexColumnUi gap={gapUi['6xl']}>
        <UiModal
          callbackBotão={() => navigate(rotasApp.meuPerfil)}
          textoBotão="Entendido"
          descrição="Todos os ajustes foram feitos com sucesso!"
          título="Alterações salvas com sucesso!"
        />

        <Box
          alignSelf="center"
          marginTop={marginUi.xl}
          position="relative"
          // {...((avatarWatch === '' || avatarWatch === null) && {
          //   as: 'label',
          //   htmlFor: 'avatar',
          // })}
        >
          <AvatarRender />
          {/* <Box
            position="absolute"
            bottom={0}
            right={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            borderRadius="full"
            background={corFundoUi.primaria}
            border={`5px solid ${corFundoUi.primaria}`}
            onClick={lidaComRemoverFoto}
          >
            <Icon
              as={avatarWatch === '' || avatarWatch === null ? ImageSquare : Trash}
              color={avatarWatch === '' || avatarWatch === null ? corPrimariaAtivoUi : corIconesUi['lixeira-foto']}
            />
          </Box> */}
        </Box>

        <FormProvider {...alunoEditarContext}>
          <FormulárioUi.Form callback={alunoEditar}>
            <FlexColumnUi gap={gapUi.xl}>
              <FormulárioUi.Container name="avatar">
                <input
                  style={{ display: 'none' }}
                  id="avatar"
                  type="file"
                  // accept="image/*"
                  accept="image/*;"
                  capture
                  onChange={handleImageUpload}
                />
              </FormulárioUi.Container>
              <FormulárioUi.Container name="idade" disabled hidden>
                <FormulárioUi.Label título="Idade" />
                <FormulárioUi.Input placeholder="Idade" type="number" />
              </FormulárioUi.Container>
              <FormulárioUi.Container name="matricula" disabled>
                <FormulárioUi.Label título="Matrícula" />
                <FormulárioUi.Input />
              </FormulárioUi.Container>
              <FormulárioUi.Container name="nome" disabled>
                <FormulárioUi.Label título="Nome" />
                <FormulárioUi.Input defaultValue={aluno?.nome} />
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
              <FormulárioUi.Container name="email" disabled>
                <FormulárioUi.Label título="E-mail" />
                <FormulárioUi.Input />
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
              <FormulárioUi.Container name="cpf" disabled>
                <FormulárioUi.Label título="CPF" />
                <FormulárioUi.InputMáscara tipo="cpf" value={aluno?.cpf} />
              </FormulárioUi.Container>
              <FormulárioUi.Container name="celular">
                <FormulárioUi.Label título="Celular" />
                <FormulárioUi.InputMáscara tipo="celular" defaultValue={aluno?.celular} />
                <FormulárioUi.MensagemErro />
              </FormulárioUi.Container>
              {aluno && aluno.idade < 18 && (
                <>
                  <Box position="relative" paddingY="5">
                    <TextoLgUi> Dados dos responsáveis</TextoLgUi>
                  </Box>
                  <FormulárioUi.Container name="nomeResponsavel">
                    <Flex gap="1">
                      <FormulárioUi.Label título="Nome do responsável" />
                      <Box as="span" color={coresUi.void} fontSize={fontSizeUi.xxl} hidden={!verifyField('nomeResponsavel')}>
                        *
                      </Box>
                    </Flex>
                    <FormulárioUi.Input autoFocus placeholder="nome" />
                    <FormulárioUi.MensagemErro />
                  </FormulárioUi.Container>
                  <FormulárioUi.Container name="emailResponsavel">
                    <Flex gap="1">
                      <FormulárioUi.Label título="E-mail do responsável" />
                      <Box as="span" color={coresUi.void} fontSize={fontSizeUi.xxl} hidden={!verifyField('emailResponsavel')}>
                        *
                      </Box>
                    </Flex>
                    <FormulárioUi.Input placeholder="email@exemple.com" />
                    <FormulárioUi.MensagemErro />
                  </FormulárioUi.Container>
                  <FormulárioUi.Container name="cpfResponsavel">
                    <Flex gap="1">
                      <FormulárioUi.Label título="CPF do responsável" />
                      <Box as="span" color={coresUi.void} fontSize={fontSizeUi.xxl} hidden={!verifyField('cpfResponsavel')}>
                        *
                      </Box>
                    </Flex>
                    <FormulárioUi.InputMáscara tipo="cpf" />
                    <FormulárioUi.MensagemErro />
                  </FormulárioUi.Container>
                  <FormulárioUi.Container name="telefoneResponsavel">
                    <Flex gap="1">
                      <FormulárioUi.Label título="Celular do responsável" />
                      <Box as="span" color={coresUi.void} fontSize={fontSizeUi.xxl} hidden={!verifyField('telefoneResponsavel')}>
                        *
                      </Box>
                    </Flex>
                    <FormulárioUi.InputMáscara tipo="celular" defaultValue={aluno?.celular} />
                    <FormulárioUi.MensagemErro />
                  </FormulárioUi.Container>
                </>
              )}
              <BotãoFormulárioUi
                type="submit"
                isLoading={alunoEditarIsLoading}
                isDisabled={isErrosFormulário(errors) || !isEdição || alunoEditarIsLoading}
              >
                Salvar alterações
              </BotãoFormulárioUi>
            </FlexColumnUi>
          </FormulárioUi.Form>
        </FormProvider>
      </FlexColumnUi>
    </ContainerAppUi>
  )
}
